import React from "react";
import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import { Layout } from "../components";

const Success = ({ location }) => {
  return (
    <Layout location={location}>
      <Box
        maxW='full'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        bg={"pink"}
      >
        <Flex justify='center' flexDir='column'>
          <Heading textAlign='center' fontSize={40}>
            Success!
          </Heading>
          <Text textAlign='center'>
            We have recieved your submission. Talk soon!
          </Text>
        </Flex>
      </Box>
    </Layout>
  );
};

export default Success;
